import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";

import { yupValidator } from "lib/yup-validator";
import Card from "atoms/Card";
import Button from "atoms/Button";
import Heading from "atoms/Heading";
import Input from "atoms/Input";
import ReactSelect from "atoms/forms/ReactSelect";
import { Profession, USER_TYPES, categoryOptions, title } from "constant";
import { GetPincodeDataForProfile, GetServicesSpecilizationList, sendOtp, getUserTypesData } from "services/user.service";
import Alert from "molecules/Alert";
import axios from "axios";
import * as moengage from "services/moengage.service";
import mo_engage_onboarding from "assets/mo_engage/onboarding";
import Checkbox from "molecules/Forms/Checkbox";
import LinkAnchor from "atoms/LinkAnchor";
import { onProfileSpecializationSearch } from "@/components/ProfileCard/ProfileMoengage";
import { moEngageAccountRegistrationSubmitGetOTP, moEngageSignupPageLoginClick } from "@/components/SignUp/LoginSignUpMonengage";
import { gaEngageAccountRegistrationSubmitGetOTP, gaEngageSignupPageLoginClick } from "@/components/SignUp/LoginSignUpGA";
import { useRouter } from "next/router";
import { decodedBufferBase64 } from "core";

const FormSignUp = ({ setIsModalOpen, apiUserData, handleLogin, userData, setIsParticipate, isOnlyForm, isShowPatient, setIsShowPatient, isShowPractice, setIsShowPractice, isShowChemist, setIsShowChemist }) => {
    const [apiHit, setApiHit] = useState(false);
    const [selectedOption] = useState(null);
    const entity = mo_engage_onboarding.entity;
    const [pincodeErrorMessage, setPincodeErrorMessage] = useState("")
    const [promotional, setpromotional] = useState(true)
    const [otp, setOtp] = useState(true)
    const router = useRouter();
    const [alertMsg, setAlertMsg] = useState(router?.query?.message ? { type: "error", message: decodedBufferBase64(router?.query?.message) } : {});
    const [listOfUserTypeData, setListOfUserTypeData] = useState([]);

    
    const {
        register,
        handleSubmit,
        control,
        watch,
        setError,
        getValues,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupValidator(
            yup.object().shape({
                mobileNo: yup.string().required("Mobile number is required").matches(/^((?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9})*$/, "Mobile number is not valid"),
                pinCode: yup.string().required("Pincode is Required").test('len', "Enter your Valid 6 digit Pin Code.", async val => {
                    if (!(/^[1-9][0-9]{5}$/.test(val))) {
                        setPincodeErrorMessage({
                            type: "len",
                            message: "Please enter a valid Pincode."
                        })
                        return false
                    }
                    setPincodeErrorMessage({})
                    return true
                }).typeError("Should be a number"),
                fullName: yup
                    .string()
                    .required("Name is Required")
                    .matches(/^[a-zA-Z ]*$/, "Name can only contain alphabets")
                    .test("match", "Name should not contain salutation.", async (value) => !/^(Mr\.|Mr\s|mr\.|mr\s|Mrs\.|Mrs\s|mrs\.|mrs\s|Miss\.|Miss\s|miss\.|miss\s|Dr\.|Dr\s|dr\.|dr\s|Sr\.|Sr\s|sr\.|sr\s)/.test(value.trim().toLocaleLowerCase()))
                    .test("match", "No special characters allowed in the name.", async (value) => !/[&\/\\#,+()$~%.'":*?<>{}]/g.test(value)),
                profession: yup.string().required("Please select profession"),
                title: yup.string().when('profession', {
                    is: val => val && val != USER_TYPES.PATIENT,
                    then: yup.string()
                        .required('Required')
                }),
                category: yup.string()
                    .when('profession', {
                        is: val => val && (val == USER_TYPES.DOCTORS || val == USER_TYPES.STUDENTS),
                        then: yup.string()
                            .required('Practice is required')
                    }),
            })
        ),
    });


    useEffect(() => {
        setIsShowPractice(router.query.type === 'practitioner');
        if (userData) {
            setValue('fullName', userData?.name)
            setValue('mobileNo', userData?.mobileNo)
            setIsParticipate(true)
        }
    }, [userData])
    useEffect(() => {
    }, [pincodeErrorMessage])

    useEffect(() => {
        if (Number(watch('profession')) == (USER_TYPES.CHEMIST || USER_TYPES.PATIENT)) {
            setValue('specializations', undefined)
            setValue('category', undefined)
        }
    }, [watch('profession')])

    useEffect(() => {
        const fetchUserTypesData = async () => {
            const userTypeData = await getUserTypesData();
            if (userTypeData?.status) {
                const data = userTypeData?.entity?.map((item, index) => ({
                    value: item?.id,
                    label: item?.displayName,
                    tooltip: Profession?.[index]?.tooltip,
                }));
                setListOfUserTypeData(data);
            } else {
                setListOfUserTypeData(Profession);
            }
        };
    
        fetchUserTypesData();
    }, []);
    

    const onSubmit = async (data) => {
        const result = await GetPincodeDataForProfile({ pincode: getValues('pinCode'), forProfile: 1 });
        if (result?.status) {
            setPincodeErrorMessage({});
        } else {
            setPincodeErrorMessage({
                type: "len",
                message: result.message[0]
            });
            return false;
        }
        let attributes = {};
        if (data.profession === '2') {
            attributes[entity["onboarding_load_sign_up_form"]["attributes_sign_up_form_click"]["type_of_profession"]] = entity?.type?.doctor_key;
        } else if (data.profession === '6') {
            attributes[entity["onboarding_load_sign_up_form"]["attributes_sign_up_form_click"]["type_of_profession"]] = entity?.type?.chemist_key;
        } else if (data.profession === '4') {
            attributes[entity["onboarding_load_sign_up_form"]["attributes_sign_up_form_click"]["type_of_profession"]] = entity?.type?.student_key;
        }
        moengage.trackEvent(entity.onboarding_load_sign_up_form.event_name, attributes);
        moengage.trackEvent(entity.onboarding_get_OTP_signup.event_name, {});
        gaEngageAccountRegistrationSubmitGetOTP();
        moEngageAccountRegistrationSubmitGetOTP();

        if ((Number(data.profession) != USER_TYPES.CHEMIST && Number(data.profession) != USER_TYPES.PATIENT && Number(data?.profession) != USER_TYPES.RETAILER) && (data.specializations == undefined || data?.specializations?.length > 5)) {
            setError('specializations', { message: data?.specializations?.length > 5 ? 'Cannot add more than 5 Specialization' : 'Specialization is required' });
            return false;
        }
        if ((Number(data.profession) != USER_TYPES.CHEMIST && Number(data.profession) != USER_TYPES.PATIENT && Number(data?.profession) != USER_TYPES.RETAILER) && data.category == undefined) {
            setError('category', { message: 'Practice is required' });
            return false;
        }

        if (otp) {
            moengage.trackEvent(entity.Onboarding_Whatsapp_OTP_Permission_Accepted.event_name, {});
        }
        if (promotional) {
            moengage.trackEvent(entity.Onboarding_Whatsapp_Promotions_Permission_Accepted.event_name, {});
        }

        data.fullName = data.fullName
            ?.trim().replace(/  +/g, " ")
            .toLowerCase()
            .replace(/(?:^\w|[A-Z]|\b\w)/g, (word) => word.toUpperCase())
            .replace(/\s+/g, ' ');
        setApiHit(true);
        let apiData = {
            mobileNumber: data.mobileNo,
            country_code: "91",
            userData: {
                title: Number(data.title),
                userType: Number(data.profession),
                full_name: data.fullName,
                pincode: data.pinCode,
                referralCode: data.referralCode,
                otpConsent: otp ? 1 : 0,
                promotionalMessageConsent: promotional ? 1 : 0,
                practice: data.category,
                specializations: data.specializations
            },
        };

        const response = await sendOtp({
            mobileNumber: apiData.mobileNumber,
            country_code: apiData.country_code,
            full_name: apiData.userData.full_name,
            userType: Number(data.profession),
        });

        if (response?.data?.message === "Successfully sent Otp!") {
            setIsModalOpen(true);
            apiUserData(apiData);
        } else {
            setAlertMsg({ message: response?.data?.message, type: "error" });
        }
    };

    const getSpecializationDropDownList = async ({ searchKey, pageNo, type }) => {
        const data = await GetServicesSpecilizationList(type, {
            search: searchKey,
            page: pageNo,
            limit: 10
        });
        return data.entity;
    }

    const handleChange = (selectedOption) => {
        if (selectedOption.value === 1) {
          router.push('/sign-up?type=individual');
        } else if (selectedOption.value === 2 || selectedOption.value === 6 || selectedOption.value === 4) {
          router.push('/sign-up?type=practitioner');
        }
      };

    const loadOptions = async (searchKey, prevOptions) => {

        const { count, rows } = await getSpecializationDropDownList({
            searchKey,
            pageNo: Math.ceil(prevOptions.length / 10) + 1,
            type: 1
        });

        const hasMore = (count / 10) === 0 ? false : Math.ceil((rows.length + prevOptions.length) / 10) < (count / 10);

        return {
            options: rows.map(item => {
                return {
                    label: item.name,
                    value: item.id
                }
            }),
            hasMore: hasMore,
        };
    };

    return (
        <div className={`${isOnlyForm ? '' : 'md:w-[60%] max-w-[500px]'} flex-shrink-0 w-full relative`}>
            {alertMsg?.message && (
                <Alert type={alertMsg.type} effect="slideDown" resetAlertCallback={() => setAlertMsg("")} stayDuration={8}>
                    {alertMsg.message}
                </Alert>
            )}
            <Card type='card--regular' className={`${isOnlyForm ? 'bg-basic-whitePure' : 'md:bg-basic-whitePure md:mt-7.5 lg:mb-5'} w-full lg:max-w-[550px] shadow-none md:shadow-sm rounded-none md:rounded-2xl-0.5 overflow-hidden`}>
                <div className="pt-5 pb-5 md:pb-5 px-6 py-5 max-w-[460px] mx-auto">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {isOnlyForm
                            ? <Heading type="h3" className="flex justify-center mb-5">Create an account</Heading>
                            : <div className="flex justify-center items-center gap-2 mb-5">
                                <Heading type="h3" className="text-22-28 flex justify-center font-semibold">
                                    Join NirogStreet
                                </Heading>
                            </div>}

                        {isOnlyForm && <Heading type="h4" className="text-lg.5 font-semibold flex justify-center mb-4 -mt-5" textColor='text-gray-600'>
                            To be a NirogPartner
                        </Heading>}

                        <Controller
                            control={control}
                            name="profession"
                            render={({ field: { onChange, name, value } }) => (
                                <ReactSelect
                                    name={name}
                                    placeholder="Select User Type*"
                                    defaultValue={router?.query?.type && router?.query?.mobile && router.query.type == "individual" ? Profession?.find(item => item?.value === 1) : null}
                                    onChange={(data) => {
                                        onChange(data?.value);
                                        handleChange(data);
                                        if (data?.value === USER_TYPES.CHEMIST) {
                                            setIsShowChemist(true);
                                            setIsShowPractice(false);
                                            setIsShowPatient(false);
                                        } else if (data?.value === USER_TYPES?.RETAILER) {
                                            setIsShowChemist(true);
                                            setIsShowPractice(false);
                                            setIsShowPatient(false);
                                        } else if (data?.value == USER_TYPES.PATIENT) {
                                            setIsShowPatient(true);
                                            setIsShowPractice(false);
                                            setIsShowChemist(false);
                                        } else if (data?.value === USER_TYPES.DOCTORS || data?.value === USER_TYPES.STUDENTS) {
                                            setIsShowPractice(true);
                                            setIsShowPatient(false);
                                            setIsShowChemist(false);
                                        }
                                    }}
                                    options={listOfUserTypeData}
                                    error={errors.profession}
                                    isTooltip={true}
                                />
                            )}
                        />

                        <div className="flex">
                            {(isShowChemist || isShowPractice) && <div className="w-24 flex-shrink-0 mr-14px">
                                <Controller
                                    control={control}
                                    name="title"
                                    render={({ field: { onChange, name, value } }) => (
                                        <ReactSelect
                                            placeholder="Title*"
                                            name={name}
                                            defaultValue={selectedOption}
                                            onChange={(data) => {
                                                onChange(data?.value);
                                            }}
                                            options={title}
                                            error={errors.title}
                                        />
                                    )}
                                />
                            </div>}
                            <Input type="text" placeholder="Full Name*" register={register} id="fullName" error={errors.fullName} isRequiredField={true} />
                        </div>
                        <div className="flex flex-col sm:flex-row md:flex-col lg:flex-row gap-x-3.5">
                            <Input
                                type="number"
                                isMobileNumber={true}
                                placeholder="Mobile Number*"
                                id="mobileNo"
                                register={register}
                                error={errors.mobileNo}
                                isRequiredField={true}
                                patternRequired={true}
                                reger="^[789]\d{9}$"
                                value={watch('mobileNo')}
                                maxLength={10}
                                setValue={setValue}
                                defaultValue={router?.query?.mobile ? Number(decodedBufferBase64(router?.query?.mobile)) : null}
                            />

                            <Input
                                type="number"
                                placeholder="Pincode*"
                                id="pinCode"
                                register={register}
                                error={pincodeErrorMessage}
                                isRequiredField={true}
                                minLength={6}
                                maxLength={6}
                                setValue={setValue}
                            />
                        </div>
                        {isShowPractice && <div className="flex flex-col gap-x-3.5">
                            <div className="w-full">
                                <Controller
                                    control={control}
                                    name="category"
                                    render={({ field: { onChange, name, value } }) => (
                                        <ReactSelect
                                            placeholder="Practice Type*"
                                            name={name}
                                            defaultValue={selectedOption}
                                            onChange={(data) => {
                                                onChange(data?.value);
                                            }}
                                            options={categoryOptions}
                                            error={errors.category}
                                        />
                                    )}
                                />
                            </div>
                            <div className="w-full">
                                <Controller
                                    control={control}
                                    name="specializations"
                                    render={({ field: { onChange, name, value } }) => (
                                        <ReactSelect
                                            placeholder="Specialization(s)*"
                                            name={name}
                                            isMulti={true}
                                            onChange={(data) => onProfileSpecializationSearch(() => {
                                                return (data.length && !data.includes(null)) ? onChange(data) : onChange(undefined);
                                            })}
                                            loadOptions={loadOptions}
                                            error={errors.specializations}
                                            dropdownTheme='CHECKBOX'
                                        />
                                    )}
                                />
                            </div>
                        </div>}


                        <Checkbox className="mb-4 mt-2" label="I provide my consent to receive promotional messages from NirogStreet via Whatsapp messages" handlerCheckboxChange={() => { setpromotional(!promotional); moengage.trackEvent(entity.Onboarding_Whatsapp_Promotions_Permission_Accepted.event_name, {}); }} checked={promotional} />
                        <Checkbox className="mb-3" label="I provide my consent to receive OTP, Transactional messages and Order updates via Whatsapp messages" handlerCheckboxChange={() => { setOtp(!otp); moengage.trackEvent(entity.Onboarding_Whatsapp_OTP_Permission_Accepted.event_name, {}) }} checked={otp} />
                        <div className="text-gray-500 font-normal text-center text-sm">
                            By clicking on “Register”, I accept the <br /> <LinkAnchor className='inline-block text-primary1-500' href="/terms-of-service" >Terms and Conditions</LinkAnchor> and  <LinkAnchor className='inline-block text-primary1-500' href="/privacy-policy"  >Privacy Policy</LinkAnchor>  of NirogStreet
                        </div>
                        <br />


                        {isOnlyForm ?
                            <div className="flex gap-3.5">
                                <Button type='button' className="max-w-75 w-full px-3 mx-auto max-h-[42px]" variant="Ghost"
                                    onClick={() => handleLogin(true)} dataAutomation='already-have-an-account'>
                                    Sign in instead
                                </Button>
                                <Button type='submit' className="max-w-75 w-full px-3 mx-auto max-h-[42px]" variant="Primary"
                                    dataAutomation='get-otp' disabled={otp ? false : true} >
                                    Register
                                </Button>
                            </div>
                            : <div className="flex gap-3.5">
                                <LinkAnchor href={router?.query?.type === 'individual' ? `/login?type=individual` : `/login?type=practitioner`} className='max-w-75 w-full px-3 mx-auto max-h-[42px]'>
                                    <Button type='button' className="rounded-lg max-w-75 w-full px-3 mx-auto max-h-[42px]" variant="Ghost"
                                        onClick={() => {
                                            gaEngageSignupPageLoginClick()
                                            moEngageSignupPageLoginClick()
                                        }
                                        } dataAutomation='already-have-an-account'>
                                        Login instead
                                    </Button>
                                </LinkAnchor>
                                <Button type='submit' className="rounded-lg max-w-75 w-full px-3 mx-auto max-h-[42px]" variant="Primary"
                                    dataAutomation='get-otp'  >
                                    Register
                                </Button>
                            </div>}
                    </form>
                </div>
            </Card>
        </div>
    );
};

export default FormSignUp;
